<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{$t('teachmasiveload.massupload')}}

                    div
                       nav.breadcrumb-one(aria-label="breadcrumb")
                          ol.breadcrumb
                                li.breadcrumb-item
                                    router-link(to="/teacher/teacherhome") {{$t('teachmasiveload.teadashboard')}}
                                li.breadcrumb-item.active(aria-current="page") {{$t('teachmasiveload.massupload')}}

                div.row.mt-5.pb-5.d-flex.align-items-center
                    div.col-lg-6.col-md-6.layout-spacing.mx-auto
                        div.statbox.widget.box.box-shadow
                            div.widget-header
                                div.row
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                            h4 {{$t('teachmasiveload.upload')}}

                                div.widget-content.widget-content-area
                                    div.form-group.mb-12
                                        h5 {{$t('teachmasiveload.instructions')}}
                                        p {{$t('teachmasiveload.ps1')}}<a :href="$t('teachmasiveload.link')">{{$t('teachmasiveload.ps1a')}}</a> </br> {{$t('teachmasiveload.ps2')}}</br>{{$t('teachmasiveload.ps3')}} </br> {{$t('teachmasiveload.ps4')}} </br> {{$t('teachmasiveload.ps5')}}
                                    div.form-group.mb-12
                                        vue-dropzone(ref="mydropzone" id="dropzone" :options="dropzoneOptions")

                                    div.form-group.mb-12
                                        textarea(v-model="message" style="width:550px" placeholder="Log")

                                            



</template>
<script>
/* eslint-disable */
export default {
    name: 'MasiveLoad',
  
    data() {
        return {
            message: '',
                dropzoneOptions: {
                                    url: apiURL+'Student/processFile',
                                    thumbnailWidth: 50,
                                    maxFileSize: 10,
                                    paramName: 'file',
                                    timeout: 600000,
                                    headers: {
                                        Authorization: 'Bearer ' + localStorage.userToken
                                    },
                                    dictDefaultMessage: this.$t('msg.dataexcel'),
                                    accept: (file, done) => {
                                        done()
                                    },
                                    success: ( file, response ) => {
                                        if(response.bIsCorrect == true){
                                            this.message = this.$t('msg.dataexcelproce')
                                        }
                                        else {
                                            console.log(response.sErrors)
                                            var result = response.sErrors.map(function(val) {
                                            return val;
                                            }).join('\n');


                                            this.message = result
                                        }
                                            
                                    }
                },
            }
    },
    methods: {

    },
    mounted() {
        this.checkPermisions(this.$router.currentRoute.path)

        }    
        
}
</script>